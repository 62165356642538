import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
// import { User } from '../_models';
import { environment } from '../../environments/environment';
import { of } from 'rxjs';
import { AppConfigService } from '../services/app-config.service';
import { NGXLogger } from 'ngx-logger';
import { UserService } from '@/services/user.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '@/models/user';
import { compareDatesBySeconds, compareDateToNowBySeconds } from '../shared/time_util';
import { BrowserStorageService } from '@/services/browserstorage.service';

// const ROOT_URL = environment.rootUrl;

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    // should these urls be taken care by urlservice
    private loginUrl;
    private logoutUrl;
    private refreshTokenUrl;
    private resetPassworddUrl;
    private resetPassworddConfirmUrl;
    private veryfyEmailUrl;
    private resendVeryfyEmailUrl;
    private PassworddChangeUrl;

    // need both ?
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    // indicate if a user is currently logged / shoud depend on previous currentUser ??
    private authChangeSubject = new BehaviorSubject<boolean>(false);
    public authChange: Observable<boolean>;
    private tokenExpirationTimer: any;

    // http options used for making API calls
    private httpOptions: any;
    // the actual JWT token
    // public token: string;
    // the token expiration date
    // public token_expires: Date;
    // the username of the logged in user
    // error messages received from the login attempt
    public errors: any = [];


    constructor(private http: HttpClient,
        private appconfigService: AppConfigService,
        // private userService: UserService,
        private router: Router,
        private browserStorageService: BrowserStorageService,
        private logger: NGXLogger
    ) {
        this.appconfigService.loadAppConfig().then(function (finalResult) {
            // console.log('Got the final result: ' + finalResult);
        });

        // console.log('AuthenticationService constructor');
        // console.log('AuthenticationService constructor rootUrl ' + this.appconfigService.config.rootUrl);

        this.loginUrl = this.appconfigService.config.rootUrl + '/en/dj-rest-auth/login/';
        this.logoutUrl = this.appconfigService.config.rootUrl + '/en/dj-rest-auth/logout/';
        this.refreshTokenUrl = this.appconfigService.config.rootUrl + '/en/dj-rest-auth/token/refresh/';
        this.resetPassworddUrl = this.appconfigService.config.rootUrl + '/en/dj-rest-auth/password/reset/';
        this.resetPassworddConfirmUrl = this.appconfigService.config.rootUrl + '/en/dj-rest-auth/password/reset/confirm/';
        this.PassworddChangeUrl = this.appconfigService.config.rootUrl + '/en/dj-rest-auth/password/change/';
        this.veryfyEmailUrl = this.appconfigService.config.rootUrl + '/en/dj-rest-auth/registration/verify-email/';
        this.resendVeryfyEmailUrl = this.appconfigService.config.rootUrl + '/en/dj-rest-auth/registration/resend-email/';

        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this.browserStorageService.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.authChange = this.authChangeSubject.asObservable();
        this.httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
    }

    public get currentUserValue(): User {
        this.logger.debug('AuthenticationService get currentUserValue' + JSON.stringify(this.currentUserSubject.value));
        return this.currentUserSubject.value;
    }

    public get currentUseId(): number {
        this.logger.debug('AuthenticationService get currentUserValue' + JSON.stringify(this.currentUserSubject.value));
        return this.currentUserSubject.value.id;
    }
    public getAccessToken(): string {
        return this.browserStorageService.getItem('access_token');
    }
    public static getAccessToken(): string {
        return 'aaa';
    }

    public getRefreshToken(): string {
        return this.browserStorageService.getItem('refresh_token');
    }

    // public static getRefreshToken(): string {
    //     return 'bbbb';
    // }
    public static decodeJWT(myRawToken: string): { expire: Date, isExpired: boolean, decodedToken: string } {
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(myRawToken);
        const expirationDate = helper.getTokenExpirationDate(myRawToken);
        const isExpired = helper.isTokenExpired(myRawToken);
        // console.log(' decodedTokeni ' + JSON.stringify(decodedToken) + ' expirationDate ' + expirationDate + ' isExpired ' + isExpired);
        return ({ expire: expirationDate, isExpired: isExpired, decodedToken: decodedToken })
    }

    login(email: string, password: string) {
        let user: User;
        user = new User();
        user.email = email;
        user.password = password;
        this.logger.debug('AuthenticationService login');
        /*
                return this.http.post<any>(`${API_URL}/users/authenticate`, { username, password })
                    .pipe(map(user => {
                        // login successful if there's a jwt token in the response
                        if (user && user.token) {
                            // store user details and jwt token in local storage to keep user logged in between page refreshes
                            this.browserStorageService.setItem('currentUser', JSON.stringify(user));
                            this.currentUserSubject.next(user);
                        }
                        return user;
                    }));
        */
        return this.http.post(this.loginUrl,
            { email: user.email, password: user.password }).pipe(
                catchError(this.handleError),
                tap(
                    data => {
                        let decoded_access_JWT;
                        let decoded_refresh_JWT;
                        console.log('AuthenticationService data ' + data);
                        if (data['access_token']) {
                            // console.log('AuthenticationService token ' + data['access_token']);
                            // this.updateData(data['access_token']);
                            decoded_access_JWT = AuthenticationService.decodeJWT(data['access_token']);
                            decoded_refresh_JWT = AuthenticationService.decodeJWT(data['refresh_token']);
                            this.browserStorageService.setItem('access_token', data['access_token']);
                            this.browserStorageService.setItem('access_token_expiresat', decoded_access_JWT.expire.toISOString());
                            this.browserStorageService.setItem('refresh_token', data['refresh_token']);
                            this.browserStorageService.setItem('refresh_token_expiresat', decoded_refresh_JWT.expire.toISOString());
                            user.token = data['access_token'];
                        }
                        if (data['access']) {
                            // console.log('AuthenticationService token ' + data['access']);
                            // this.updateData(data['access']);
                            decoded_access_JWT = AuthenticationService.decodeJWT(data['access']);
                            decoded_refresh_JWT = AuthenticationService.decodeJWT(data['refresh']);
                            this.browserStorageService.setItem('access_token', data['access']);
                            this.browserStorageService.setItem('access_token_expiresat', decoded_access_JWT.expire.toISOString());
                            this.browserStorageService.setItem('refresh_token', data['refresh']);
                            this.browserStorageService.setItem('refresh_token_expiresat', decoded_refresh_JWT.expire.toISOString());
                            user.token = data['access'];
                        }
                        console.log('AuthenticationService userdata ' + JSON.stringify(data));
                        let accessValidityInSeconds = compareDateToNowBySeconds(this.browserStorageService.getItem('access_token_expiresat'));
                        let refreshValidityInSeconds = compareDateToNowBySeconds(this.browserStorageService.getItem('refresh_token_expiresat'));
                        this.logger.debug(' login accessValidityInSeconds : ' + accessValidityInSeconds);
                        this.logger.debug(' login refreshiValidityInSeconds : ' + refreshValidityInSeconds);

                        // ??? language vs locale & ngxtranslate
                        this.browserStorageService.setItem('language', 'us');

                        this.httpOptions = {
                            //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Userization': 'JWT ' + AuthenticationService.getJWTToken() })
                        };
                        user = data['user'];
                        this.browserStorageService.setItem('currentUser', JSON.stringify(user));
                        this.currentUserSubject.next(user);
                        // this.token = data['token'];
                        // user.id = data['user']['id'];
                        // take off unecessary
                        // this.http.get<User>(this.appconfigService.config.apiUrl + '/users/' + user.id).subscribe((usr) => {
                        //     this.logger.debug('returned user: ' + JSON.stringify(usr));
                        //     user.pseudo = usr.pseudo;
                        //     user.photocontainer = usr.photocontainer;
                        //     user.photoname = usr.photoname;
                        //     this.browserStorageService.setItem('currentUser', JSON.stringify(user));
                        //     this.currentUserSubject.next(user);
                        //     this.token = data['token'];
                        // });
                        this.authSuccessfully();
                    },
                    // (error: HttpErrorResponse) => {
                    //     // let validationErrorDictionary = JSON.parse(error.text());
                    //     // for (var fieldName in validationErrorDictionary) {
                    //     //     if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                    //     //         this.errors.push(validationErrorDictionary[fieldName]);
                    //     //     }
                    //     // }
                    //     // this.alertService.errorMsg(this.errors);
                    //     console.log('error status ' + error.status);
                    //     console.log('error.error : ' + error.error[0]);
                    // }
                )
            );
        // , shareReplay());
        // .subscribe();

        // return (of(user));
    }
    autoLogin() {
        let loadedUser: User;
        loadedUser = JSON.parse(this.browserStorageService.getItem('currentUser'));
        if (!loadedUser) {
            this.logger.debug(' autologin not logged');
            return;
        } else {
            this.logger.debug(' autologin user in local storage');
            this.currentUserSubject.next(loadedUser);
            let accessValidityInSeconds = compareDateToNowBySeconds(this.browserStorageService.getItem('access_token_expiresat'));
            this.logger.debug(' autologin accessValidityInSeconds : ' + accessValidityInSeconds);
            let refreshValidityInSeconds = compareDateToNowBySeconds(this.browserStorageService.getItem('refresh_token_expiresat'));
            this.logger.debug(' autologin refreshValidityInSeconds : ' + refreshValidityInSeconds);
            this.logger.debug(' autologin refreshing token in local storage');
            this.refreshToken().subscribe(
                {
                    next: (token: any) => {
                        this.browserStorageService.setItem('access_token', token.access);
                        this.browserStorageService.setItem('refresh_token', token.refresh);
                        this.logger.debug(' autologin token refreshed and stored in local storage');
                    },
                    error: (err) => {
                        console.log('autologin error refreshing token :' + err);
                        this.logout();
                    }
                }
            );
            this.logger.debug(' autologin token present');
            this.authSuccessfully();
            const expirationDuration = 10000000;
            // new Date(userData._tokenExpirationDate).getTime() -
            // new Date().getTime();
            // not much intersting to autologout on mobile
            // this.autoLogout(expirationDuration);
        }
    }

    private authSuccessfully() {
        this.authChangeSubject.next(true);
        // this.router.navigate(['/home']);

    }
    isLoggedIn() {
        if (this.browserStorageService.getItem('currentUser') == null) {
            return false;
        } else {
            return true;
        }
    }

    isOwner(user_id: string) {
        let currentUser1: User;

        currentUser1 = JSON.parse(this.browserStorageService.getItem('currentUser'));
        if (currentUser1 == null) {
            return false;
        } else {
            if (currentUser1.id === +user_id) {
                return true;
            }
        }
    }
    // Refreshes the JWT token, to extend the time the user is logged in
    public refreshToken() {
        this.logger.debug('refreshing JWT token');
        // AuthenticationService.decodeJWT(AuthenticationService.getRefreshToken());
        // return this.http.post(this.refreshTokenUrl, { refresh: AuthenticationService.getRefreshToken() });
        return this.http.post(this.refreshTokenUrl, { refresh: this.getRefreshToken() });
        // ,
        // this.httpOptions);
        // // .subscribe(
        //     data => {
        //         this.updateData(data['token']);
        //     },
        //     err => {
        //         this.errors = err['error'];
        //     }
        // );
    }

    // private updateData(token) {
    //     this.token = token;
    //     this.errors = [];

    //     const token_parts = this.token.split(/\./);
    //     const token_decoded = JSON.parse(window.atob(token_parts[1]));
    //     this.token_expires = new Date(token_decoded.exp * 1000);
    //     console.log('updateData token decoded : ' + JSON.stringify(token_decoded));
    //     // console.log('update data token : ' + token);

    // }

    logout() {
        // remove user from local storage to log user out
        console.log('logout :');
        this.browserStorageService.removeItem('currentUser');
        this.browserStorageService.removeItem('access_token');
        this.browserStorageService.removeItem('refresh_token');
        this.currentUserSubject.next(null);
        // new
        // this.token = null;
        // this.token_expires = null;
        this.authChangeSubject.next(false);
        this.router.navigate(['/login']);
    }

    autoLogout(expirationDuration: number) {
        this.tokenExpirationTimer = setTimeout(() => {
            this.logout();
        }, expirationDuration);
    }

    /* called when password has been forgoten */
    resetPwd(email: string) {
        console.log('AuthenticationService resetpwd email : ' + email);
        this.http.post(this.resetPassworddUrl, { email: email })
            .subscribe(
                data => {
                    console.log('AuthenticationService resetPwd data' + data);
                    // { "detail": "Password reset e-mail has been sent." }

                },
                err => {
                    this.errors = err['error'];
                    console.log('AuthenticationService resetPwd error : ' + err['error']);
                }
            );
        return (of({ 'detail': 'Password reset e-mail has been sent.' }));
    }

    /* use to setup a new password */
    passwordResetConfirm(uid: string, token: string, new_password1: string, new_password2: string) {
        this.http.post(this.resetPassworddConfirmUrl,
            { uid: uid, token: token, new_password1: new_password1, new_password2: new_password2 })
            .subscribe(
                data => {
                    console.log('AuthenticationService passwordResetConfirm data' + data);
                },
                err => {
                    this.errors = err['error'];
                    console.log('AuthenticationService error : ' + err['error']);
                }
            );
        return (of({ 'detail': 'Password reset sent.' }));
    }


    verifyEmail(token: string) {
        this.logger.debug('verifyEmail with token ' + token)
        this.http.post(this.veryfyEmailUrl,
            { key: token })
            .subscribe(
                data => {
                    console.log('AuthenticationService verifyEmail data' + data);
                },
                err => {
                    this.errors = err['error'];
                    console.log('AuthenticationService error : ' + err['error']);
                }
            );
        return (of({ 'detail': 'Password reset sent.' }));
    }

    /* called when password has been forgoten */
    resendVerifyEmail(email: string) {
        console.log('AuthenticationService resend verification email : ' + email);
        this.http.post(this.resendVeryfyEmailUrl, { email: email })
            .subscribe(
                data => {
                    console.log('AuthenticationService resetPwd data' + data);
                    // { "detail": "Password reset e-mail has been sent." }

                },
                err => {
                    this.errors = err['error'];
                    console.log('AuthenticationService resetPwd error : ' + err['error']);
                }
            );
        return (of({ 'detail': 'Password reset e-mail has been sent.' }));
    }
    /* called to change password  a lttle bit defferent from forgot paasword :
    here the old password is known */
    public passwordChange(new_password1, new_password2, old_password): Observable<any> {
        console.log('AuthenticationService change password: ' + old_password + new_password1 + new_password2);
        return this.http.post(this.PassworddChangeUrl,
            { new_password1: new_password1, new_password2: new_password2, old_password: old_password });
        // .subscribe(
        //     data => {
        //         console.log('AuthenticationService passwordChange data' + data);
        //     },
        //     err => {
        //         this.errors = err['error'];
        //         console.log('AuthenticationService passwordChange error : ' + err['error']);
        //     }
        // );
    }

    /*
        / rest - auth / facebook / (POST)
    access_token
    code
    */



    private handleError(errorRes: HttpErrorResponse) {
        console.log(' login failed');
        console.log(JSON.stringify(errorRes));

        let errorMessage = 'An unknown error occurred!';
        if (!errorRes.error || !errorRes.error.non_field_errors) {
            console.log(' login unknown error case');
            // throwError(() => {
            //     const error: any = new Error(errorMessage);
            //     error.timestamp = Date.now();
            //     return error;
            // });
            return throwError(errorMessage);
        }
        if (errorRes.error.non_field_errors) {
            // if (errorRes.error.non_field_errorsi[0]
            // "E-mail is not verified.)"

            console.log(errorRes.error.non_field_errors[0]);
            console.log(' Unable to log in with provided credentials.');
            errorMessage = errorRes.error.non_field_errors[0]
        }
        if (errorRes.error.email) {
            console.log(' This email does not exist.');
            errorMessage = 'This email does not exist.';
        }
        // return new Error(errorMessage);
        return throwError(() =>
            new Error(errorMessage)
            // error.timestamp = Date.now();
            // return error;
        );
    }
}

