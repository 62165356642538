import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, PLATFORM_ID, SimpleChanges, ViewChild } from '@angular/core';
import { Serie } from '../models/serie';
import { SerieService } from '../services/serie.service';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, fromEvent, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { Page } from '../pagination';
import { AuthenticationService } from '@/_services';
import { AppConfigService } from '@/services/app-config.service';
import { NGXLogger } from 'ngx-logger';
import { ImageService } from '@/services/image.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OriginConnectionPosition } from '@angular/cdk/overlay';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteSerieDialogComponent } from '@/delete-serie-dialog/delete-serie-dialog.component';
import { RatingDialogComponent } from '@/serie-detail/rating-dialog/rating-dialog.component';
import { SharingDialogComponent } from '@/serie-detail/sharing-dialog/sharing-dialog.component';
import { isPlatformBrowser } from '@angular/common';
import { LanguageService } from '@/services/language.service';

@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.scss']
})

export class SeriesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() author_id: number;
  @Input() title = 'Series';
  @Input() displayMode = 'list'; // possible value list or grid
  @Input() displayTitle = false;
  @Input() chooseDisplayMode = false;
  @Input() series: Serie[];
  @Input() virtualDisplay: boolean = false;
  filterForm: FormGroup;
  series$: Observable<Serie[]>;

  currentRate = 8;
  currentRate2 = 5;

  cols = 3;
  rowHeight = '500px';
  handsetPortrait = false;

  @ViewChild('virtualViewport1') public virtualViewport1: ElementRef;
  @ViewChild('virtualViewport2') public virtualViewport2: ElementRef;
  protected deactivatedSubject: Subject<boolean> = new Subject<boolean>();

  nblikes: number = 0;
  nbcomments: number = 0;
  nbsubscriptions: number = 0;
  nbratings: number = 0;
  nbshares: number = 0;

  constructor(private ref: ChangeDetectorRef,
    private serieService: SerieService,
    public authenticationService: AuthenticationService,
    private appconfigService: AppConfigService,
    private imageService: ImageService,
    private responsive: BreakpointObserver,
    private languageService: LanguageService,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: {},
    private logger: NGXLogger) {
  }

  ngOnInit() {
    //    this.getSeries();
    if (this.author_id) {
      // this.logger.debug('series from author_id:');
      this.series$ = this.getAuthorSeries(this.author_id);
    } else if (this.series) {
      // this.logger.debug('series set:');
      // this.logger.debug('series :' + JSON.stringify(this.series));
      this.series$ = of(this.series);
    } else {
      // this.logger.debug('series not set:');
      this.series$ = this.getSeries();
    }

    this.responsive.observe([Breakpoints.TabletPortrait,
    Breakpoints.TabletLandscape,
    Breakpoints.HandsetPortrait,
    Breakpoints.HandsetLandscape
    ])
      .subscribe(result => {
        console.log(result);
        this.cols = 3;
        this.rowHeight = '325px';
        this.handsetPortrait = false;


        const breakpoints = result.breakpoints;
        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.cols = 1;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.cols = 1;
          this.rowHeight = '325px';
          this.handsetPortrait = true;
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.cols = 1;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.cols = 2;
        }

      });

    if (isPlatformBrowser(this.platformId)) {
      fromEvent(window, 'resize')
        .pipe(
          distinctUntilChanged(),
          debounceTime(10),
          // sampleTime(0, animationFrameScheduler),
          takeUntil(this.deactivatedSubject)
        ).subscribe(() => {
          if (this.virtualViewport1)
            (<any>this.virtualViewport1).checkViewportSize();
          if (this.virtualViewport2)
            (<any>this.virtualViewport2).checkViewportSize();
        });
    }

  }

  getAuthorSeries(author_id: number): Observable<Serie[]> {
    return this.serieService.getAuthorSeries(author_id);
  }

  getSeries(): Observable<Serie[]> {
    this.logger.debug('call seriesService getSeries:');
    return this.serieService.getSeries();
  }

  rateChange(rating) {
    this.currentRate2 = rating;
    console.log(`rating is ${rating}`);
    console.log(rating);
    // this.serieService.rateSerie(this.serie, rating).subscribe();
  }

  add(name: string): void {
    name = name.trim();
    if (!name) { return; }
    this.serieService.addSerie({ name } as Serie)
      .subscribe(serie => {
        this.series.push(serie);
      });
  }

  delete(serie: Serie): void {
    this.series = this.series.filter(s => s !== serie);
    this.serieService.deleteSerie(serie).subscribe();
  }

  subscribe(serie: Serie): void {
    this.serieService.subscribeSerie(serie).subscribe();
  }

  toggledisplayMode(): void {
    this.displayMode = (this.displayMode === 'list') ? 'grid' : 'list';
  }
  onValChange(value) {
    this.logger.debug('onvalChange')
    console.log(value);
    this.displayMode = value;
  }
  ngOnChanges(changes: SimpleChanges) {
    // this.logger.debug('onChanges');
    // this.logger.debug('onChanges changes ' + JSON.stringify(changes));
    this.series$ = of(this.series); // ???
    // this.ref.detectChanges();
  }
  //   TriggerChange() {
  //     this.ref.detectChanges();
  //   }

  public ngOnDestroy() {
    this.deactivatedSubject.next(true);
    this.deactivatedSubject.complete();
  }




  openShareDialog(): void {
    const dialogRef = this.dialog.open(SharingDialogComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openRateDialog(): void {
    const dialogRef = this.dialog.open(RatingDialogComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  deleteSerieDialog(serie_id: number): void {
    this.logger.debug('deleteSerieDialog ' + serie_id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: serie_id,
    };
    const dialogRef = this.dialog.open(DeleteSerieDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (this.author_id) {
        // this.logger.debug('series from author_id:');
        this.series$ = this.getAuthorSeries(this.author_id);
      } else {
        // this.logger.debug('series not set:');
        this.series$ = this.getSeries();
      }

    });
  }
  // getNbLikes(serieId: number): Observable<number> {

  //   return this.serieService.nblikes(serieId);
  // }
  // this.serieService.nbcomments(id).subscribe(data => this.nbcomments = data);
  // this.serieService.isratedbymeSerie(id).subscribe(data => { this.isratedbyme = data; });
  // this.serieService.nbratings(id).subscribe(data => { this.nbratings = data; });
  // this.serieService.myrating(id).subscribe(data => { this.myrating = data; });
  // this.serieService.nbsubscriptions(id).subscribe(data => this.nbsubscriptions = data);
}

