//  Time-stamp: "2016-07-25 06:21:04 MDT"
module.exports = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', "", "", "",
// '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]', '[?]',
'a',
//  0x63
'e',
//  0x64
'i',
//  0x65
'o',
//  0x66
'u',
//  0x67
'c',
//  0x68
'd',
//  0x69
'h',
//  0x6a
'm',
//  0x6b
'r',
//  0x6c
't',
//  0x6d
'v',
//  0x6e
'x',
//  0x6f

'[?]', '[?]', '[?]', '[?]', "'", ",", '[?]', '[?]', '[?]', '[?]', "", '[?]', '[?]', '[?]', "?", '[?]', '[?]', '[?]', '[?]', '[?]', "", "", 'A', ";", 'E', 'E', 'I', '[?]', 'O', '[?]', 'U', 'O', 'I', 'A', 'B', 'G', 'D', 'E', 'Z', 'E', 'Th', 'I', 'K', 'L', 'M', 'N', 'Ks', 'O', 'P', 'R', '[?]', 'S', 'T', 'U', 'Ph', 'Kh', 'Ps', 'O', 'I', 'U', 'a', 'e', 'e', 'i', 'u', 'a', 'b', 'g', 'd', 'e', 'z', 'e', 'th', 'i', 'k', 'l', 'm', 'n', 'x', 'o', 'p', 'r', 's', 's', 't', 'u', 'ph', 'kh', 'ps', 'o', 'i', 'u', 'o', 'u', 'o', '[?]', 'b', 'th', 'U', 'U', 'U', 'ph', 'p', "&", '[?]', '[?]', 'St', 'st', 'W', 'w', 'Q', 'q', 'Sp', 'sp', 'Sh', 'sh', 'F', 'f', 'Kh', 'kh', 'H', 'h', 'G', 'g', 'CH', 'ch', 'Ti', 'ti',
// 03F0:
//  ϰ ϱ ϲ ϳ  
'k', 'r', 'c', 'j',
//  ϴ ϵ ϶ Ϸ 
'TH', 'e', 'e', 'Sh',
//  ϸ Ϲ Ϻ ϻ
'sh', 's', '[?]', '[?]',
//  ϼ Ͻ Ͼ Ͽ
'r/', 'S', 'S.', 'S.'];