<button *ngIf="fullscreen"
        mat-icon-button
        title="like episode"
        (click)="noFullscreen()">
     <mat-icon aria-label="toggle fullscreen">fullscreen</mat-icon>
</button>
<!-- (tap)="onTap()" -->
<swiper #swiper
        [slidesPerView]="1"
        [spaceBetween]="50"
        (swiper)="onSwiper($event)"
        (slideChange)="onSlideChange()"
        (reachEnd)="onReachEnd($event)"
        [navigation]="true"
        [pagination]="{ clickable: true }"
        [scrollbar]="{ draggable: true }">
     <ng-template swiperSlide
                  *ngFor="let photo of photos; let i = index">
          <!-- <div id="editbuton">
               <button routerLink="/editphoto/{{photo.id}}"
                       mat-icon-button
                       title="edit photo">
                    <mat-icon aria-label="edit">edit</mat-icon>
               </button>
               <button mat-icon-button
                       (click)="toggleText()">
                    <mat-icon>textsms</mat-icon>
               </button>
          </div> -->
          <!-- <a routerLink="/editphoto/{{photo.id}}">edit photo</a> -->


          <div id="{{ 'divimage' + photo.id }}"
               class="image">
               <div class="containerimg">
                    <img #myImg
                         id="{{ 'myimg' + photo.id }}"
                         class="contentimage"
                         [src]="imageService.gphotourl(photo.photocontainer,photo.photoname)"
                         alt="Random slide">
               </div>
               <div id="{{ 'textimg' + photo.id }}"
                    class="textimg">
               </div>
               <svg #mySvg
                    id="{{ 'svg' + photo.id }}"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute;top:0px;left:0px"
                    zindex="9"
                    width="100%"
                    height="100%">
               </svg>
               <!-- svg.setAttribute('width', String(this.myImg.nativeElement.clientWidth));
        svg.setAttribute('height', String(this.myImg.nativeElement.clientHeight));
         -->
          </div>
     </ng-template>
</swiper>

<!-- [aria-label="alertsEnabled ? 'Disable alerts' : 'Enable alerts'" -->