
import { Component, OnInit, Input, ChangeDetectorRef, Renderer2, ElementRef, ViewChild, AfterViewInit, HostListener, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser, Location } from '@angular/common';
import { Episode } from '../models/episode';
import { EpisodeService } from '../services/episode.service';
import { AuthorService } from '@/services/author.service';
import { Author } from '@/models/author';
import { AuthenticationService } from '@/_services';
import { BehaviorSubject, Observable, Subscription, map, of, shareReplay, switchMap } from 'rxjs';
import { ImageService } from '@/services/image.service';
import { PhotoService } from '@/services/photo.service';
import { NGXLogger } from 'ngx-logger';
import * as e from 'cors';
import { SharingDialogComponent } from '../serie-detail/sharing-dialog/sharing-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Photo } from '@/models/photo';
import { Texte, TexteType, TexteShapeType, TexteDisplayType } from '@/models/texte';
import { createSpeakLine, createThoughtLine, computeArrowPoints, hello, createEllipse, computeArrowPoints8, createPhylactereOvale, createPhylactereRect, genImageWithTextFast } from '../shared/util';
import { TexteService } from '@/services/texte.service';
import { TranslateService } from '@ngx-translate/core';
import { DeletePhotoDialogComponent } from '@/delete-photo-dialog/delete-photo-dialog.component';
import { UploadService } from '@/services/upload.service';
import { GenphotosnumDialogComponent } from './genphotosnum-dialog/genphotosnum-dialog.component';
import { TelechargeComponent } from '@/telecharge/telecharge.component';
import { UserService } from '@/services/user.service';
import { User } from '@/models/user';
import { waitForElement } from '@/shared/time_util';
import { AppConfigService } from '@/services/app-config.service';
import { LanguageService } from '@/services/language.service';



@Component({
  selector: 'app-episode-linearcarrousel',
  templateUrl: './episode-linearcarrousel.component.html',
  styleUrls: ['./episode-linearcarrousel.component.scss']
})
export class EpisodeLinearcarrouselComponent implements OnInit, AfterViewInit {


  @ViewChild('myImg', { static: false }) myImg: ElementRef;
  @Input() episode: Episode;
  @Input() displayPhotoMenu: Boolean = true;
  display_comment: boolean = true;
  episode_id: number;
  episode$: Observable<Episode>;
  photos$: Observable<Photo[]>;
  photosSubject: BehaviorSubject<Photo[]>;
  author: Author;
  author$: Observable<Author>;
  fullscreenSubscription: Subscription;
  fullscreen: boolean;
  isbookmarkedbyme$: Observable<boolean>
  isbookmarkedbyme: boolean
  photos: Photo[];

  islikedbyme: boolean[] = [];
  iscommentedbyme: boolean[] = [];
  nblikes: number[] = [];
  display_comments: boolean[] = [];
  nbcomments: number[] = [];
  currentUserSubscription: Subscription;
  currentUser: User = null;
  isEditorSubscription: Subscription;
  isEditor: boolean = false;

  private fragment: string;

  isShow: boolean = true;
  topPosToStartShowing = 100;
  firstimageid: number;


  // @HostListener('window:scroll')
  // checkScroll() {

  //   // window의 scroll top
  //   // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

  //   const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

  //   console.log('[scroll]', scrollPosition);

  //   if (scrollPosition >= this.topPosToStartShowing) {
  //     this.isShow = true;
  //   } else {
  //     this.isShow = false;
  //   }
  // }

  // TODO: Cross browsing
  gotoTop() {
    const targetElement = document.querySelector('#' + 'myimg' + String(this.firstimageid));
    targetElement.scrollIntoView()
    // window.scroll({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth'
    // });
  }

  constructor(
    private ref: ChangeDetectorRef,
    private appconfigService: AppConfigService,
    private route: ActivatedRoute,
    private episodeService: EpisodeService,
    private photoService: PhotoService,
    private languageService: LanguageService,
    private authorService: AuthorService,
    public authenticationService: AuthenticationService,
    public userService: UserService,
    private uploadService: UploadService,
    private imageService: ImageService,
    private texteService: TexteService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private logger: NGXLogger,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: {},
    private render: Renderer2
  ) { }

  ngOnInit(): void {
    this.logger.debug('episode component init');
    // this.logger.debug('episode json : ' + JSON.stringify(this.episode));
    this.episode_id = +this.route.snapshot.paramMap.get('id');

    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });

    // this.episode$ = this.getEpisode(this.episode_id);
    this.episode$ = of(this.episode)
    // this.author$ = this.getEpisodeAuthor(this.episode_id);
    this.author$ = of(this.episode.owner);
    // this.photos$ = this.getEpisodePhotos(this.episode_id);
    this.photosSubject = new BehaviorSubject<Photo[]>([]);
    // this.photosSubject = this.getEpisodePhotos(this.episode_id).pipe(switchMap(() => ));
    this.photos$ = this.photosSubject.asObservable();
    this.getEpisodePhotos(this.episode_id).subscribe(res => this.photosSubject.next(res));
    // this.photos$.subscribe(page => {
    //   this.photos = page;

    //   //    this.images[1] = 'http://127.0.0.1:8000/media/photos/2018/07/29/4006l-cygne-tubercule-cygnus-olor.jpg';
    //   //    this._http.get('https://picsum.photos/list')
    //   //      .pipe(map((images: Array<{ id: number }>) => this._randomImageUrls(images)))
    //   //     .subscribe(images => this.images = images);
    //   // if (this.translate.currentLang != 'fr') {
    //   // for (let photo of this.photos) {
    //   //   this.texteService.getPhotoTextes(photo.id).subscribe(y => {
    //   //     // this.imgratio = this.myImg.nativeElement.clientWidth / this.myImg.nativeElement.naturalWidth;
    //   //     // this.logger.debug('carrousel imgratio : ' + this.imgratio);
    //   //     // this.logger.debug('carrousel clientw nat w : ' +
    //   //     // this.myImg.nativeElement.clientWidth + ' , ' + this.myImg.nativeElement.naturalWidth);
    //   //     for (const txt of y.results) {
    //   //       // this.logger.debug('carrousesel txt: ', txt.texte);
    //   //       // this.drawTexte(photo.id, photo.size_x, photo.size_y, txt);
    //   //     }
    //   //   });
    //   // }
    //   // }
    // });
    this.fullscreenSubscription = this.photoService.fullscreen.subscribe(fs => {
      // this.ref.markForCheck();
      // this.logger.debug('episode component changefullscreen value  ' + fs);
      this.fullscreen = fs;
      // this.ref.marpkForCheck()i
      this.ref.detectChanges();
      // this.logger.debug('episode component changefullscreen value 2 ' + fs);
    });

    // for (let photo of this.photos) {
    //   // this.logger.debug('carrousesel photoid: ', photo.id);
    //   this.texteService.getPhotoTextes(photo.id).subscribe(y => {
    //     // this.imgratio = this.myImg.nativeElement.clientWidth / this.myImg.nativeElement.naturalWidth;
    //     // this.logger.debug('carrousel imgratio : ' + this.imgratio);
    //     // this.logger.debug('carrousel clientw nat w : ' +
    //     // this.myImg.nativeElement.clientWidth + ' , ' + this.myImg.nativeElement.naturalWidth);
    //     for (const txt of y.results) {
    //       // this.logger.debug('carrousesel txt: ', txt.texte);
    //       this.drawTexte(photo.id, photo.size_x, txt);
    //     }
    //   });
    // }
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    if (this.authenticationService.isLoggedIn()) {
      this.isEditorSubscription = this.userService.isEditor(this.authenticationService.currentUseId).subscribe(res => {
        this.isEditor = res;
      });
    }
  }

  ngAfterViewInit() {

    // this.logger.debug('photos : ' + JSON.stringify(this.photos));
    this.photos$.subscribe(page => {
      this.photos = page;

      if (this.photos.length > 0) {

        console.log('photos length : ' + this.photos.length);
        const lastphotoid = this.photos[this.photos.length - 1].id;
        console.log('photos length : ' + this.photos.length);
        console.log('last photos id : ' + this.photos[this.photos.length - 1].id);
        //    this.images[1] = 'http://127.0.0.1:8000/media/photos/2018/07/29/4006l-cygne-tubercule-cygnus-olor.jpg';
        //    this._http.get('https://picsum.photos/list')
        //      .pipe(map((images: Array<{ id: number }>) => this._randomImageUrls(images)))
        //     .subscribe(images => this.images = images);
        // oif (this.translate.currentLang != 'fr') {
        let i = 0;
        for (let photo of this.photos) {
          console.log('photo : ' + i);
          if (i == 0)
            this.firstimageid = photo.id

          this.display_comments.push(false)
          // this.photoService.islikedbyme(photo.id).subscribe(data => this.islikedbyme.push(data));
          this.islikedbyme.push(photo.islikedbyme);
          // this.photoService.iscommentedbyme(photo.id).subscribe(data => this.islikedbyme.push(data));
          this.iscommentedbyme.push(photo.iscommentedbyme);
          // s.photoService.is.nblikes(photo.id).subscribe(data => this.nblikes.push(data));
          this.nblikes.push(photo.nb_likes);
          // this.photoService.nbcomments(photo.id).subscribe(data => this.nbcomments.push(data));
          this.nbcomments.push(photo.nb_comments);
          // this.texteService.getPhotoTextes(photo.id).subscribe(y => {

          let y = photo.textes
          //     // this.imgratio = this.myImg.nativeElement.clientWidth / this.myImg.nativeElement.naturalWidth;
          //     // this.logger.debug('carrousel imgratio : ' + this.imgratio);
          //     // this.logger.debug('carrousel clientw nat w : ' +
          //     // this.myImg.nativeElement.clientWidth + ' , ' + this.myImg.nativeElement.naturalWidth);
          let ind = 0

          if (isPlatformBrowser(this.platformId)) {
            let intervalId = setInterval(() => {
              const targetElement = document.querySelector('#myimg' + photo.id);
              if (targetElement) {
                clearInterval(intervalId);
                for (let txt of y) {
                  //       // this.logger.debug('carrousesel txt: ', txt.texte);
                  this.logger.debug('i ' + i + '  texte : ' + JSON.stringify(txt));
                  //   const lasttargetElement = document.querySelector('#myimg' + lastphotoid);
                  ind++;
                  console.log('ind : ' + ind);
                  console.log('intervalId : ' + intervalId);
                  //   console.log('txt : ' + txt.id + 'txt ' + txt.texte);

                  //     console.log('found photo' + photo.id + ' is now available to draw text ' + txt.id + ' on');
                  this.drawTexte(photo.id, photo.size_x, photo.size_y, txt);
                  let bottombuttons = document.getElementById('bottombuttons' + photo.id) as HTMLImageElement;
                  let lespoints = this.comutePhotoPoints(photo.id, photo.size_x, photo.size_y);
                  // // myImg.style.top = (lespoints.imgbottommiddle_y - 60) + 'px';
                  if (lespoints.imgbottomgap_y < 60)
                    bottombuttons.style.top = (lespoints.imgbottommiddle_y - (60 - lespoints.imgbottomgap_y)) + 'px';
                  else
                    bottombuttons.style.top = (lespoints.imgbottommiddle_y) + 'px';
                  // // myImg.style.left = lespoints.imgbottommiddle_x + 'px';
                  bottombuttons.style.left = '0px';
                }
                console.log('target element  not ready photo #myimg' + photo.id);
              }
            }, 400);
            console.log('after setinterval for texte of photo ' + photo.id);
            i++;
            if (ind > 8) {
              console.log('ind > 6 setinterval for texte of photo ' + photo.id);
              clearInterval(intervalId);
            }
          }
        } // for photos
      } // photos.length > 0)
    });
    // try to scroll to anchor did not work
    // try {
    //   this.logger.debug('EpisodeLinearcarrouselComponent ngAfterViewInit scrollIntoView #' + this.fragment)
    //   document.querySelector('#' + this.fragment).scrollIntoView();
    // } catch (e) { }
    // for (let photo of this.photos) {
    //   // this.logger.debug('carrousesel photoid: ', photo.id);
    //   this.texteService.getPhotoTextes(photo.id).subscribe(y => {
    //     // this.imgratio = this.myImg.nativeElement.clientWidth / this.myImg.nativeElement.naturalWidth;
    //     // this.logger.debug('carrousel imgratio : ' + this.imgratio);
    //     // this.logger.debug('carrousel clientw nat w : ' +
    //     // this.myImg.nativeElement.clientWidth + ' , ' + this.myImg.nativeElement.naturalWidth);
    //     for (const txt of y.results) {
    //       // this.logger.debug('carrousesel txt: ', txt.texte);
    //       this.drawTexte(photo.id, photo.size_x, photo.size_y, txt);
    //     }
    //   });
    // waitForElement(document.querySelector('#' + this.fragment)).then(el => el.scrollIntoView());

    if (isPlatformBrowser(this.platformId)) {
      if (this.fragment) {
        console.log('Fragment set in url');
        let ind = 0
        let intervalFId = setInterval(() => {
          console.log('Fragment set');
          const targetElement = document.querySelector('#' + this.fragment);

          if (targetElement) {
            clearInterval(intervalFId);
            console.log('Element is now available');
            targetElement.scrollIntoView()
          }
        }, 400);
        if (ind > 8) {
          console.log('ind > 6 setinterval for fragment');
          clearInterval(intervalFId);
        }
      }
    }
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
    if (this.isEditorSubscription)
      this.isEditorSubscription.unsubscribe();

  }

  refreshPhotos() {
    this.getEpisodePhotos(this.episode_id).subscribe(res => this.photosSubject.next(res));
    this.photos$.subscribe(page => {
      this.photos = page;
      let i = 0;
      for (let photo of page) {
        this.display_comments.push(false)
        this.photoService.islikedbyme(photo.id).subscribe(data => this.islikedbyme.push(data));
        this.photoService.iscommentedbyme(photo.id).subscribe(data => this.iscommentedbyme.push(data));
        this.photoService.nblikes(photo.id).subscribe(data => this.nblikes.push(data));
        this.photoService.nbcomments(photo.id).subscribe(data => this.nbcomments.push(data));
        this.texteService.getPhotoTextes(photo.id).subscribe(y => {
          for (const txt of y) {
            this.drawTexte(photo.id, photo.size_x, photo.size_y, txt);
          }
        });
        const bottombuttons = document.getElementById('bottombuttons' + photo.id) as HTMLImageElement;
        let lespoints = this.comutePhotoPoints(photo.id, photo.size_x, photo.size_y);
        // myImg.style.top = (lespoints.imgbottommiddle_y - 60) + 'px';
        if (lespoints.imgbottomgap_y < 60)
          bottombuttons.style.top = (lespoints.imgbottommiddle_y - (60 - lespoints.imgbottomgap_y)) + 'px';
        else
          bottombuttons.style.top = (lespoints.imgbottommiddle_y) + 'px';
        // myImg.style.left = lespoints.imgbottommiddle_x + 'px';
        bottombuttons.style.left = '0px';
        i++;
      }
    });
  }
  getEpisode(id: number): Observable<Episode> {
    return this.episodeService.getEpisode(id);
  }

  getEpisodePhotos(episode_id: number): Observable<Photo[]> {
    return this.photoService.getEpisodePhotos(episode_id);
    //   .pipe(
    //   shareReplay(1)
    // );;
  }

  getEpisodeAuthor(id: number): Observable<Author> {
    return this.episodeService.getEpisodeAuthor(id);
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.episodeService.updateEpisode(this.episode)
      .subscribe(() => this.goBack());
  }

  likephoto(photo: Photo, index: number): void {
    this.photoService.likePhoto(photo).subscribe();
    this.islikedbyme[index] = !this.islikedbyme[index];
    if (this.islikedbyme[index])
      this.nblikes[index] += 1;
    else
      this.nblikes[index] -= 1;
  }

  bookmark(episode: Episode): void {
    this.episodeService.bookmarkEpisode(episode).subscribe();
  }

  genphotosnumDialog() {
    this.logger.debug('EditPhotoComponent textTypeDialog: ');

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      max_num: 50,
    };

    const dialogRef = this.dialog.open(GenphotosnumDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log("Dialog output:", data)
        this.genRangeImages(data.min, data.max)
      });
  }

  openShareDialog(): void {
    const dialogRef = this.dialog.open(SharingDialogComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  deletePhotoDialog(photo_id: number): void {
    this.logger.debug('deletePhotoDialog ' + photo_id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: photo_id,
    };
    const dialogRef = this.dialog.open(DeletePhotoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.photos$ = this.getEpisodePhotos(this.episode_id);
    });
  }
  // createSVG(id) {
  //   const svg = document.createElementNS('http://www.w3.org/2000/svg',
  //     'svg');
  //   svg.setAttribute('id', 'svg' + id);
  //   svg.setAttribute('style', 'position:absolute;top:0px;left:0px');
  //   svg.setAttribute('width', String(this.myImg.nativeElement.clientWidth));
  //   svg.setAttribute('height', String(this.myImg.nativeElement.clientHeight));
  //   svg.style.zIndex = '9';
  //   svg.setAttributeNS("http://www.w3.org/2000/xmlns/",
  //     "xmlns:xlink",
  //     "http://www.w3.org/1999/xlink");
  //   // document.body.appendChild(svg);
  //   return svg;
  // }


  public moveUp(photo: Photo) {
    this.logger.debug('moveUP photo numero' + photo.numero);
    this.photoService.moveUp(photo).subscribe();
    this.photos$ = this.getEpisodePhotos(this.episode_id);
  }

  public moveDown(photo: Photo) {
    this.logger.debug('moveDown photo numero' + photo.numero);
    this.photoService.moveDown(photo).subscribe();
    this.photos$ = this.getEpisodePhotos(this.episode_id);
  }

  comutePhotoPoints(photoid: number, size_x: number, size_y: number): {
    imgtopleft_x: number,
    imgtopleft_y: number,
    imgbottomright_x: number,
    imgbottomright_y: number,
    imgbottommiddle_x: number,
    imgbottommiddle_y: number
    imgbottomgap_y: number,
  } {
    let imgratio_x: number;
    let imgratio_y: number;
    let divimgratio: number;
    let imgratio: number;

    let imgtopleft_x: number = 0;
    let imgtopleft_y: number = 0;
    let imgbottomright_x: number = 0;
    let imgbottomright_y: number = 0;
    let imgbottommiddle_x: number = 0;
    let imgbottommiddle_y: number = 0;
    let imgbottomgap_y: number = 0;

    // this.logger.debug('comutePhotoPoints photoid :' + photoid);

    const myImg = document.getElementById('myimg' + photoid) as HTMLImageElement;;
    // imgratio = myImg.clientWidth / myImg.naturalWidth;
    divimgratio = myImg.clientWidth / myImg.clientHeight
    imgratio = size_x / size_y
    imgratio_x = myImg.clientWidth / size_x;
    imgratio_y = myImg.clientHeight / size_y;

    if (imgratio > divimgratio) {
      imgtopleft_x = 0;
      imgtopleft_y = (myImg.clientHeight - imgratio_x * size_y) / 2;
      imgbottomright_x = myImg.clientWidth;
      imgbottomright_y = (myImg.clientHeight + imgratio_x * size_y) / 2;
      imgbottommiddle_x = myImg.clientWidth / 2;
      imgbottommiddle_y = (myImg.clientHeight + imgratio_x * size_y) / 2;
    }
    else {
      imgtopleft_x = (myImg.clientWidth - imgratio_y * size_x) / 2;
      imgtopleft_y = 0; // ok
      imgbottomright_x = (myImg.clientWidth + imgratio_y * size_x) / 2;
      imgbottomright_y = myImg.clientHeight; // ok
      imgbottommiddle_x = myImg.clientWidth / 2;
      imgbottommiddle_y = myImg.clientHeight;
    }
    imgbottomgap_y = myImg.clientHeight - imgbottomright_y;
    return {
      imgtopleft_x: imgtopleft_x,
      imgtopleft_y: imgtopleft_y,
      imgbottomright_x: imgbottomright_x,
      imgbottomright_y: imgbottomright_y,
      imgbottommiddle_x: imgbottommiddle_x,
      imgbottommiddle_y: imgbottommiddle_y,
      imgbottomgap_y: imgbottomgap_y
    };
  }

  drawTexte(photoid: number, size_x: number, size_y: number, txt: Texte) {
    const logger = this.logger;
    let svg: SVGSVGElement;
    let imgratio_x: number;
    let imgratio_y: number;
    let divimgratio: number;
    let imgratio: number;
    let textDivtop, textDivleft: number;
    let boundaryX2, boundaryY2: number;
    let imgtopleft_x: number = 0;
    let imgtopleft_y: number = 0;

    this.logger.debug('draw text photo id  ' + photoid + '   texte: ' + txt);
    // this.logger.debug('drawTexte txt id  ' + txt.id);

    const myImg = document.getElementById('myimg' + photoid) as HTMLImageElement;;
    // imgratio = myImg.clientWidth / myImg.naturalWidth;
    divimgratio = myImg.clientWidth / myImg.clientHeight
    imgratio = size_x / size_y
    imgratio_x = myImg.clientWidth / size_x;
    imgratio_y = myImg.clientHeight / size_y;

    if (imgratio > divimgratio) {
      imgtopleft_x = 0; //ok
      imgtopleft_y = (myImg.clientHeight - imgratio_x * size_y) / 2
    }
    else {
      imgtopleft_x = (myImg.clientWidth - imgratio_y * size_x) / 2;
      imgtopleft_y = 0; // ok
    }

    // this.logger.debug('drawtext divimgratio =' + String(divimgratio));
    // this.logger.debug('drawtext imgratio =' + String(imgratio));
    // this.logger.debug('drawtext imgratio_x =' + String(imgratio_x));
    // this.logger.debug('drawtext imgratio_y =' + String(imgratio_y));
    // this.logger.debug('drawtext clientwidth =' + String(myImg.clientWidth));
    // this.logger.debug('drawtext clientHeight =' + String(myImg.clientHeight));
    // this.logger.debug('drawtext naturalwidth =' + String(myImg.naturalWidth));


    const textImg = document.getElementById('textimg' + photoid) as HTMLImageElement;;
    document.querySelectorAll(`[data-textid="${txt.id}"]`).forEach(e => e.remove());

    const textDiv = document.createElement('div');
    textImg.appendChild(textDiv);
    const textContent = document.createTextNode(txt.texte);
    textDiv.appendChild(textContent);

    textDiv.setAttribute('draggable', 'false');
    textDiv.setAttribute('data-textid', String(txt.id));
    textDiv.id = String(txt.id);
    this.render.addClass(textDiv, 'text-block');
    // textDiv.classList.add('text-block');
    // textDiv.style.backgroundColor = 'rgba(255,248,220,0.1)';
    textDiv.style.backgroundColor = txt.background_color;
    textDiv.style.fontSize = txt.font_size;
    // textDiv.style.backgroundColor = 'Cornsilk';
    textDiv.style.whiteSpace = 'pre';
    textDiv.style.zIndex = '10';
    textDiv.style.position = 'absolute';
    textDiv.style.borderRadius = '7px';
    // textDiv.style.boxSizing = 'border-box';
    textDiv.style.padding = '7px';
    textDiv.style.border = '0px solid';
    // textDiv.style.fontSize = '0.8rem';
    // this.logger.debug('drawTexte textDiv id  ' + textDiv.id);
    // this.logger.debug('drawTexte textDiv pos  top: ' + textDiv.style.top + ' , left: ' + textDiv.style.left);


    if (imgratio > divimgratio) {
      textDivtop = Math.floor(txt.position_y * imgratio_x);
      textDivleft = Math.floor(txt.position_x * imgratio_x);
    }
    else {
      textDivtop = Math.floor(txt.position_y * imgratio_y);
      textDivleft = Math.floor(txt.position_x * imgratio_y);
    }
    textDivtop += imgtopleft_y;
    textDivleft += imgtopleft_x;

    // this.logger.debug(' textdiv top =' + textDivtop);
    // this.logger.debug(' teYxtdiv left =' + textDivleft);
    // this.logger.debug('drawTexte textDiv bbox : ' + JSON.stringify(bbox));

    let bbox = textDiv.getBoundingClientRect();
    const PADDING = 0;
    const boundaryX1 = 0 + PADDING;
    const boundaryY1 = 0 + PADDING;
    if (imgratio > divimgratio) {
      boundaryX2 = myImg.clientWidth - PADDING;
      boundaryY2 = size_y * imgratio_x - PADDING;
    } else {
      boundaryX2 = size_x * imgratio_y - PADDING;
      boundaryY2 = myImg.clientHeight - PADDING;
    }
    // this.logger.debug('drawTexte boundary x2 y2 : ' + boundaryX2 + ' ' + boundaryY2);
    let minX = boundaryX1;
    let maxX = boundaryX2 - bbox.width;
    let minY = boundaryY1;
    let maxY = boundaryY2 - bbox.height;
    minX += imgtopleft_x;
    maxX += imgtopleft_x;
    minY += imgtopleft_y;
    maxY += imgtopleft_y;
    // logger.debug('minX maxX miny maxY ' +
    //   minX + ' ' + maxX + ' ' + minY + ' ' + maxY);
    // let dy = textDiv.offsetTop;
    // let dx = textDiv.offsetLeft;
    let dy = textDivtop;
    let dx = textDivleft;
    if (dx < minX) {
      dx = minX;
      // logger.debug('dx constrained ' + dx);
    } else if (dx > maxX) {
      dx = maxX;
      // logger.debug('dx constrained ' + dx);
    }
    if (dy < minY) {
      dy = minY;
      // logger.debug('dy constrained ' + dy);
    } else if (dy > maxY) {
      dy = maxY;
      // logger.debug('dy constrained ' + dy);
    }
    // logger.debug('dx dy last ' + dx + ' ' + dy);
    textDiv.style.top = String(dy) + 'px';
    textDiv.style.left = String(dx) + 'px';

    svg = null;
    svg = <SVGSVGElement><any>document.getElementById('svg' + photoid);

    let startxl = txt.arrow_x * imgratio;
    let startyl = txt.arrow_y * imgratio;
    if (imgratio > divimgratio) {
      startxl = txt.arrow_x * imgratio_x;
      startyl = txt.arrow_y * imgratio_x;
    }
    else {
      startxl = txt.arrow_x * imgratio_y;
      startyl = txt.arrow_y * imgratio_y;
    }
    startxl += imgtopleft_x;
    startyl += imgtopleft_y;

    const arrowPoints = computeArrowPoints8(logger, textDiv.offsetLeft, textDiv.offsetTop,
      textDiv.offsetWidth, textDiv.offsetHeight,
      startxl, startyl);
    // logger.debug('startxl yl :' + startxl + ' , ' + startyl);
    // logger.debug('arrow points :' + JSON.stringify(arrowPoints));
    // logger.debug('txt :' + txt.type);

    if (txt.display === TexteDisplayType.DISPLAY_BOTH || txt.display === TexteDisplayType.DISPLAY_ARROW) {
      if (txt.type === TexteType.pensee) {
        createThoughtLine(logger, svg, imgratio, txt.id, txt.background_color, startxl, startyl, arrowPoints.x1, arrowPoints.y1);
      }
      // else if (txt.type === TexteType.parole) {
      //   createSpeakLine(logger, svg, imgratio, txt.id, txt.background_color, startxl, startyl, arrowPoints.x1, arrowPoints.y1, arrowPoints.x2, arrowPoints.y2);
      // }
    }

    textDiv.style.backgroundColor = 'rgba(255,255,255,0)';
    textDiv.style.border = '0px solid';

    if (txt.shape === TexteShapeType.ovale) {
      createPhylactereOvale(logger, svg, imgratio, txt.id, txt.background_color,
        startxl, startyl, arrowPoints.x1, arrowPoints.y1, arrowPoints.x2, arrowPoints.y2,
        textDiv.offsetLeft, textDiv.offsetTop, textDiv.offsetWidth, textDiv.offsetHeight, txt.type);
    }
    else if (txt.shape === TexteShapeType.rectangle) {
      createPhylactereRect(logger, svg, imgratio, txt.id, txt.background_color,
        startxl, startyl, arrowPoints.x1, arrowPoints.y1, arrowPoints.x2, arrowPoints.y2,
        textDiv.offsetLeft, textDiv.offsetTop, textDiv.offsetWidth, textDiv.offsetHeight, txt.type);
    }

    // if (txt.display === TexteDisplayType.DISPLAY_BOTH || txt.display === TexteDisplayType.DISPLAY_BORDER) {
    //   if (txt.shape === TexteShapeType.ovale) {
    //     textDiv.style.backgroundColor = txt.background_color;
    //     // textDiv.style.backgroundColor = 'rgba(255,248,220,0.1)';
    //     textDiv.style.border = '0px solid';
    //     textDiv.style.backgroundColor = 'rgba(255,255,255,0)';
    //     createEllipse(logger, svg, imgratio, txt.id, txt.background_color,
    //       textDiv.offsetLeft, textDiv.offsetTop, textDiv.offsetWidth, textDiv.offsetHeight);
    //   }
    //   else if (txt.shape === TexteShapeType.rectangle) {
    //     textDiv.style.backgroundColor = txt.background_color
    //     textDiv.style.border = '1px solid';
    //   }

    // } else {
    //   textDiv.style.border = '0px solid';

    // }
  }
  genImages() {
    let lstimg = [];
    this.logger.debug("genImages")
    this.photos$.subscribe(page => {
      for (let photo of page) {
        let imgDiv = document.getElementById('divimage' + photo.id);
        let newfile = genImageWithTextFast(this, imgDiv, photo.numero + '.png', 'tiktok');
        lstimg.push(newfile);
        // this.logger.debug('generated img' + genImageWithTextFast(this, imgDiv));
      }
      this.logger.debug('list mg===============' + lstimg.toString());
    });


  }
  genRangeImages(min: number, max: number) {
    let lstimg = [];
    this.logger.debug("genImages")
    this.photos$.subscribe(page => {
      for (let photo of page) {
        if ((photo.numero >= min) && (photo.numero <= max)) {

          let imgDiv = document.getElementById('divimage' + photo.id);
          let newfile = genImageWithTextFast(this, imgDiv, photo.numero + '.png', 'tiktok');
          lstimg.push(newfile);
        }
        // this.logger.debug('generated img' + genImageWithTextFast(this, imgDiv));
      }
      this.logger.debug('list mg===============' + lstimg.toString());
    });


  }
  newPhotoDialogo(episode_id: number): void {
    this.logger.debug('newPhotoDialog ' + episode_id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      episode_id: episode_id,
    };
    const dialogRef = this.dialog.open(TelechargeComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The telecharge dialog was closed');
    });
    this.refreshPhotos()
  }

  newPhotoDialog(episode_id: number): void {
    this.logger.debug('newPhotoDialog ');
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      photo_type: 'photo',
      object_id: 555,
      episode_id: 0,
      uuidfname: 'zaeaze'
    };
    dialogConfig.height = '600px';
    dialogConfig.width = '400px';
    dialogConfig.position = {
      left: '10px', top: '10px'
    }

    const dialogRef = this.dialog.open(TelechargeComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        console.log('The telecharge dialog was closed with result : ' + JSON.stringify(result));
        this.createPhoto(result.uuidFileName, result.size_x, result.size_y, episode_id).subscribe(id => {
          this.refreshPhotos();
        }
          // shoud we set the tags of the bolg at thi point
        );
      }
      else
        console.log('The telecharge dialog was closed with undefined photoId : ');
    });
  }
  createPhoto(uuidFileName: string, size_x: number, size_y: number, episode_id: number): Observable<number> {
    const photo: Photo = new Photo();
    let photo_id: number = 0;
    photo.desc = 'desccccc ' + uuidFileName;
    photo.numero = 0;
    photo.episode = episode_id;
    // photo.url = 'https://sapicafrou.blob.core.windows.net/uploads/' + fileURL;
    photo.url = '';
    photo.photocontainer = this.appconfigService.config.STORAGE_CONTAINER_NAME;
    photo.photoname = uuidFileName;
    photo.size_x = size_x;
    photo.size_y = size_y;


    return this.photoService.addPhoto(photo)
      .pipe(map(newphoto => { return newphoto.id }));
  }

}
