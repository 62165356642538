<div *ngIf="(episode$ | async) as episode">
    <!-- <div *ngIf="(author$ | async) as author"> -->
    <!-- [ngStyle]="{'display': fullscreen ? 'none' : ''}"> -->
    <mat-card appearance="outlined"
              [ngStyle]="{'display': fullscreen ? 'none' : ''}"
              class="my-card">

        <mat-card-header>
            <div class="topheader">
                <button mat-icon-button
                        class="back"
                        (click)="goBack()">
                    <mat-icon><span class="material-symbols-outlined">
                            arrow_back_ios
                        </span></mat-icon>
                </button>
                <img mat-card-avatar
                     class="myavatar episodethumbnail"
                     *ngIf="(imageService.ogphotourl(episode.photocontainer,episode.photoname)|async) as imgurl"
                     [src]="imgurl"
                     alt="Desc 1"
                     (click)="openfullscreenimageDialog()">
                <div class="toph2">
                    <mat-card-title>{{episode.title}}</mat-card-title>
                    <div class="episodelink">
                        <a
                           routerLink="{{'/' + languageService.getActivateLanguage()}}/series/{{episode.serie.id}}/{{episode.serie.name|wptodashlower}}">
                            <mat-card-subtitle>{{episode.serie.name}}</mat-card-subtitle>
                        </a>
                    </div>
                    <div class="author">
                        <!-- <a routerLink="{{'/' + languageService.getActivateLanguage()}}/authors/{{author.id}}"> -->
                        <a
                           routerLink="{{'/' + languageService.getActivateLanguage()}}/authors/{{episode.owner.id}}/{{episode.owner.pseudo}}">
                            <!--                  <img mat-card-image src="{{author.photourl}}" alt="Photo of a Shiba Inu">-->
                            <mat-card-subtitle class="author">by
                                &#64;{{episode.owner.pseudo}}</mat-card-subtitle>
                            <!-- &#64;{{author.pseudo}}</mat-card-subtitle> -->
                        </a>
                    </div>
                </div>
            </div>
        </mat-card-header>
        <mat-card-content>
            <mat-card-subtitle class="multilinetext">{{episode.desc}}</mat-card-subtitle>
        </mat-card-content>
        <mat-card-actions align="end">

            <button mat-icon-button
                    title="like episode"
                    [disabled]="!authenticationService.isLoggedIn()"
                    (click)="like(episode)">
                <mat-icon [matBadge]="nblikes"
                          matBadgePosition="after"
                          [ngStyle]="{'color': islikedbyme ? 'yellow' : 'blue'}"
                          color="warn"
                          aria-label="likes">favorite</mat-icon>
            </button>
            <button mat-icon-button
                    [disabled]="!authenticationService.isLoggedIn()"
                    mat-icon-button
                    title="comment episode"
                    (click)="display_comment=!display_comment">
                <mat-icon [matBadge]="nbcomments"
                          [ngStyle]="{'color': iscommentedbyme ? 'yellow' : 'blue'}"
                          matBadgePosition="after"
                          aria-label="Example icon-button with a heart icon">comment</mat-icon>
            </button>
            <button mat-icon-button
                    [disabled]="!authenticationService.isLoggedIn()"
                    mat-icon-button
                    title="bookmark episode"
                    (click)="bookmark(episode)">
                <mat-icon [matBadge]="nbbookmarks"
                          matBadgePosition="after"
                          [ngStyle]="{'color': isbookmarkedbyme ? 'yellow' : 'blue'}"
                          aria-label="Example icon-button with a heart icon">bookmark</mat-icon>
            </button>

            <button [disabled]="!authenticationService.isLoggedIn()"
                    mat-icon-button
                    (click)="openShareDialog()"
                    title="share episode">
                <mat-icon [matBadge]="15"
                          matBadgePosition="after"
                          aria-label="share">share</mat-icon>
            </button>
            <!-- <button *ngIf="authenticationService.isOwner(author.id)" -->
            <button *ngIf="authenticationService.isOwner(episode.owner.id)"
                    mat-icon-button
                    (click)="deleteEpisodeDialog(episode.id)"
                    title="delete episode">
                <mat-icon aria-label=" delete episode">delete</mat-icon>
            </button>
            <!-- <button *ngIf="authenticationService.isOwner(author.id)" -->
            <button *ngIf="authenticationService.isOwner(episode.owner.id)"
                    mat-icon-button
                    routerLink="{{'/' + languageService.getActivateLanguage()}}/editepisode/{{episode.id}}"
                    title="edit episode">
                <mat-icon aria-label="heart icon">edit</mat-icon>
            </button>
        </mat-card-actions>
    </mat-card>
    <app-comments [comment_type]="'episode'"
                  [episode_id]="episode.id"
                  [ngStyle]="{'display': display_comment ? 'none' : ''}"></app-comments>
    <!-- <app-photos [episode_id]="episode.id"></app-photos> -->
    <app-episode-linearcarrousel [episode]="episode"></app-episode-linearcarrousel>
    <!-- <app-carousssel [episode_id]="episode.id"></app-carousssel> -->
    <!-- *ngIf="!fullscreen" -->
    <!-- </div> -->
</div>