<!-- class="fixed-header" -->
<mat-toolbar class="header"
             color="primary">
    <span>
        <button mat-icon-button
                (click)="onToggleSidenav()">
            <mat-icon>menu</mat-icon>
        </button>
    </span>
    <!-- <span>
        <app-change-language>
        </app-change-language>
            <option *ngFor="let lang of translate.getLangs()"
    </span> -->
    <!-- <label>

        <select #langSelect
                (change)="changeLang(langSelect.value)">
            <option *ngFor="let lang of getLanguages()"
                    [value]="lang"
                    [selected]="lang === getCurrentlanguage()">{{ lang }}</option>
        </select>
    </label> -->
    <!-- <select #langSelect
                    (change)="changeLang(langSelect.value)">
                <option *ngFor="let lang of translate.getLangs()"
                        [value]="lang"
                        [selected]="lang === translate.currentLang">{{ lang }}</option>
            </select> -->
    <!-- <span>
        <mat-select-country appearance="outline"
                            [value]="defaultvalue"
                            label="country"
                            [countries]="predefinedcountries"
                            [formcontrol]="countryformcontrol"
                            (oncountryselected)="oncountryselected($event)">
        </mat-select-country>
    </span> -->

    <span class="brand">- {{title}} -</span>
    <!-- <span class="example-spacer">
    </span> -->
    <a routerLink="{{'/' + languageService.getActivateLanguage()}}/mydashboard/1">
        <span *ngIf="currentUser">
            <!-- <span *ngIf="isLoggedIn"> -->
            <h1 *ngIf="currentUser"> &#64;{{currentUser.pseudo}} </h1>
        </span>
        <ng-container *ngIf="currentUser">
            <img mat-card-avatar
                 class="myavatar"
                 *ngIf="(imageService.ogphotourl(currentUser.photocontainer,currentUser.photoname)|async) as imgurl"
                 [src]="imgurl"
                 alt="Desc 1"
                 routerLink="{{'/' + languageService.getActivateLanguage()}}/mydashboard/1">
        </ng-container>
    </a>
    <!-- <span>
        <span *ngIf="false">
        <a mat-button
           [matMenuTriggerFor]="dropdown">
            <mat-icon>menu</mat-icon>
            menu
        </a>
        <mat-menu #dropdown>
            <button mat-menu-item
                    routerLink="/home">
                <mat-icon>home</mat-icon>
                home
            </button>
            <button mat-menu-item
                    routerLink="/settings">
                <mat-icon>settings</mat-icon>
                settings
            </button>
            <button mat-menu-item
                    routerLink="/profile">
                <mat-icon>person</mat-icon>
                profile
            </button>
            <button mat-menu-item
                    routerLink="/register">
                <mat-icon>account_box</mat-icon>
                {{ "register" | translate }}
            </button>

            <button mat-menu-item
                    [matMenuTriggerFor]="serie">
                <mat-icon>home</mat-icon>
                series
            </button>
            <button mat-menu-item
                    [matMenuTriggerFor]="serie">
                <mat-icon>home</mat-icon>
                episodes
            </button>
            <button mat-menu-item
                    [matMenuTriggerFor]="serie">
                <mat-icon>home</mat-icon>
                authors
            </button>
            <button mat-menu-item
                    [matMenuTriggerFor]="serie">
                <mat-icon>home</mat-icon>
                comments
            </button>
        </mat-menu>
        <mat-menu #serie>
            <button mat-menu-item
                    routerLink="/series">
                <mat-icon>home</mat-icon>
                list
            </button>
            <button mat-menu-item
                    routerLink="/series/new">
                <mat-icon>add</mat-icon>
                create
            </button>
            <button mat-menu-item
                    routerLink="/editserie">
                <mat-icon>edit</mat-icon>
                edit
            </button>
            <button mat-menu-item
                    routerLink="/search">
                <mat-icon>search</mat-icon>
                search
            </button>
            <button mat-menu-item
                    routerLink="/search">
                <mat-icon>search</mat-icon>
                my subscribed series
            </button>
            <button mat-menu-item
                    routerLink="/search">
                <mat-icon>search</mat-icon>
                most subscribed
            </button>
            <button mat-menu-item
                    routerLink="/search">
                <mat-icon>search</mat-icon>
                most recent
            </button>
            <button mat-menu-item
                    routerLink="/search">
                <mat-icon>search</mat-icon>
                most viewed
            </button>
            <button mat-menu-item
                    routerLink="/search">
                <mat-icon>search</mat-icon>
                best rated
            </button>
            <button mat-menu-item
                    routerLink="/search">
                <mat-icon>search</mat-icon>
                search
            </button>
        </mat-menu>
    </span> -->
    <!-- <span>
        <button mat-icon-button
                routerLink="/search">
            <mat-icon>search</mat-icon>
        </button>
    </span> -->
    <!-- (click)="onClose()"> -->
    <!-- <span>
        <button mat-icon-button
                (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </button>
    </span> -->
    <!-- <mat-icon class="screen"
              *ngIf="!isFullScreen"
              (click)="openFullscreen()">fullscreen</mat-icon>
    <mat-icon class="screen"
              *ngIf="isFullScreen"
              (click)="closeFullscreen()">fullscreen_exit</mat-icon> -->
    <!-- <app-myheader></app-myheader> -->
    <!-- <span>
        <button mat-icon-button
                routerLink="/search"
                (click)="onClose()">
            <mat-icon>search</mat-icon>
        </button>
    </span> -->
</mat-toolbar>