import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

interface Configuration {
  apiVersion: string;
  apiUrl: string;
  rootUrl: string;
  versionCheckURL: string;
  STORAGE_ACCOUNT_NAME: string;
  STORAGE_CONTAINER_NAME: string;
  sasGeneratorUrl: string;
  MOCKSTORAGE: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: Configuration;
  private httpClient: HttpClient;

  constructor(handler: HttpBackend,
    @Inject(PLATFORM_ID) private platformId: {},
    // private http: HttpClient,
    // @Optional() @Inject(REQUEST) private request: any
    @Inject('APP_BASE_URL') @Optional() private readonly baseUrl: string,
    @Inject('APP_BASE_HREF') @Optional() private readonly baseHref: string
  ) {
    console.log('load config construYpctor APP_BASE_URL ' + this.baseUrl);
    console.log('load config construYpctor APP_BASE_HREF ' + this.baseHref);
    if (isPlatformBrowser(platformId)) {
      this.baseUrl = document.location.origin;
      console.log('on broser base url ' + this.baseUrl);
    }
    else
      if (isPlatformServer(platformId)) {
        // this.baseUrl = this.request.protocol + '://' + this.request.hostname + ':' + port;
        this.baseUrl = 'http://localhost:4200';
        console.log('on server base url ' + this.baseUrl);
      }

    this.httpClient = new HttpClient(handler);
  }

  loadAppConfig() {
    console.log('on server  loadAppConfig base url ' + this.baseUrl);
    return this.httpClient.get(
      `${this.baseUrl}/assets/appConfig.json`
      // `http://localhost:4000/assets/appiCCCConfig.json`
    )
      .toPromise()
      .then(data => {
        this.appConfig = <Configuration>data;
        console.log('loadAppConfig config ' + JSON.stringify(this.appConfig));
      });
    //   .catch (error => {
    //     console.warn("Error loading app-config.json, using envrionment file instead");
    //     this.appConfig = environment;
    //   })
  }

  get config() {
    return this.appConfig;
  }
}
