import { NgxLoggerLevel } from "ngx-logger";

export const environment = {
  production: true,
  //  apiUrl: 'http://127.0.0.1:8000/en/api',
  apiUrl: 'http://192.168.99.100:31654/en/api',
  //  rootUrl: 'http://127.0.0.1:8000'
  rootUrl: 'http://192.168.99.100:31654',
  versionCheckURL: 'http://picafrou.com/version.json',
  sasGeneratorUrl: 'https://sapicafrou.azurewebsites.net',
  defaultLanguage: 'en',
  logging: {
    level: NgxLoggerLevel.DEBUG,
    enableSourceMaps: true

    // serverLogLevel: NgxLoggerLevel.ERROR
    // serverLogginUrl: 'https://devdactic.free.beeceptor.com/logs'
  },
  googleAnalytics: 'G-MEG5GGHCC4'
};
