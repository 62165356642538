<mat-dialog-content [formGroup]="texteForm">
    <mat-form-field>
        <mat-select #langSelect
                    (change)="changeLang(langSelect.value)"
                    [(value)]="currentLanguage">
            <mat-option *ngFor="let lang of getLanguages()"
                        [value]="lang"
                        [selected]="lang === getCurrentlanguage()">{{ lang }}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button id="id_parolebtn"
            (click)="close()"
            class="btn btn-primary">Cancel</button>
    <button id="id_parolebtn"
            (click)="save()"
            class="mat-raised-button  btn btn-primary">Ok</button>
</mat-dialog-actions>