import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-adpanel',
  standalone: true,
  imports: [],
  templateUrl: './adpanel.component.html',
  styleUrl: './adpanel.component.scss'
})
export class AdpanelComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: {}
  ) {
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        try {
          (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
        } catch (e) {
          console.error("ads", e);
        }
      }, 900);
    }
  }


}
