import { AuthenticationService } from '@/_services';
import { queryPaginatedAllLimit, Page, queryPaginated } from '@/pagination';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, map, tap, catchError, of } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { MessageService } from './message.service';
import { BrowserStorageService } from '@/services/browserstorage.service';
import { isPlatformBrowser } from '@angular/common';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private apiVersion: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: {},
    private messageService: MessageService,
    private appconfigService: AppConfigService,
    public translate: TranslateService,
    private browserStorageService: BrowserStorageService,
    private logger: NGXLogger
  ) {
    this.apiVersion = this.appconfigService.config.apiVersion;
    let apiversion = '1.0';
  }

  getLang(): string {
    // this.logger.debug('getLang current language : ' + this.translate.currentLang)
    let lang = this.browserStorageService.getItem('locale')
    if (lang && isPlatformBrowser(this.platformId)) {
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      this.browserStorageService.setItem('locale', 'fr');
      lang = 'fr';
    }
    return lang;
  }

  getRootUrl(): string {
    // this.logger.debug('urlService    getRootUrl ');
    return this.appconfigService.config.rootUrl;
  }
  getApiUrlPath(): string {
    // this.logger.debug('urlService    getApiUrlPath ');
    return `/${this.getLang()}/1.0/api/`;
  }
  getApiUrl(): string {
    // this.logger.debug('urlService    getApiUrl ');
    return this.getRootUrl() + this.getApiUrlPath();
  }

  getUserSettingUrl(): string {
    // this.logger.debug('urlService    getUserSettingUrl ');
    return this.getApiUrl() + 'usersettings/';
  }

  getUserUrl(): string {
    // this.logger.debug('urlService    getUserUrl ');
    return this.getApiUrl() + 'users/';
  }

  getSeriesUrl(): string {
    // this.logger.debug('urlService    getSeriesUrl ');
    return this.getApiUrl() + 'series/';
  }

  getEpisodesUrl(): string {
    // this.logger.debug('urlService    getEpisodesUrl ');
    return this.getApiUrl() + 'episodes/';
  }

  getPhotosUrl(): string {
    // this.logger.debug('urlService    getPhotosUrl ');
    return this.getApiUrl() + 'photos/';
  }

  getTextesUrl(): string {
    // this.logger.debug('urlService    getTextesUrl ');
    return this.getApiUrl() + 'textes/';
  }
  getSubscriptionsUrl(): string {
    // this.logger.debug('urlService    getSubscriptionsUrl ');
    return this.getApiUrl() + 'subscriptions/';
  }
  getRatingsUrl(): string {
    // this.logger.debug('urlService    getRatingsUrl ');
    return this.getApiUrl() + 'ratings/';
  }
  getFollowsUrl(): string {
    // this.logger.debug('urlService    getFollowUrl ');
    return this.getApiUrl() + 'follows/';
  }
  getLikesUrl(): string {
    // this.logger.debug('urlService    getLikesUrl ');
    return this.getApiUrl() + 'likes/';
  }
  getBookmarksUrl(): string {
    // this.logger.debug('urlService    getBookmarksUrl ');
    return this.getApiUrl() + 'bookmarks/';
  }
  getMyBookmarkedEpisodesUrl(): string {
    // this.logger.debug('urlService    getBookmarksUrl ');
    return this.getApiUrl() + 'bookmarks/mybookmarkedepisodes/';
  }
  getLikeSeriesUrl(): string {
    // this.logger.debug('urlService    getLikeSeriesUrl ');
    return this.getApiUrl() + 'likeseries/';
  }
  getLikePhotosUrl(): string {
    // this.logger.debug('urlService    getLikePhotosUrl ');
    return this.getApiUrl() + 'likephotos/';
  }
  getAuthorsUrl(): string {
    // this.logger.debug('urlService    getAuthorsUrl ');
    return this.getApiUrl() + 'authors/';
  }
  getCommentsUrl(): string {
    // this.logger.debug('urlService    getCommentsUrl ');
    return this.getApiUrl() + 'comments/';
  }
  getCommentSeriesUrl(): string {
    // this.logger.debug('urlService    getCommentSeriesUrl ');
    return this.getApiUrl() + 'commentseries/';
  }
  getCommentPhotosUrl(): string {
    // this.logger.debug('urlService    getCommentPhotosUrl ');
    return this.getApiUrl() + 'commentphotos/';
  }
  getCategoriesUrl(): string {
    // this.logger.debug('urlService    this.urlService. ');
    return this.getApiUrl() + 'categorys/';
  }
  private httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + AuthenticationService.getJWTToken() })
  };

  /** Log a SerieService message with the MessageService */
  private log(message: string) {
    this.messageService.add('SerieService: ' + message);
  }
}

