import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { BrowserStorageService } from '@/services/browserstorage.service';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";



@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private readonly defaultLanguage = 'en';
  private readonly englishLanguage = 'en';
  private readonly frenchLanguage = 'fr';
  private readonly germanLanguage = 'de';
  private readonly supportedLanguages = [this.englishLanguage, this.frenchLanguage, this.germanLanguage]

  public getActivateLanguage(): string {
    return this.translate.currentLang
  }
  constructor(private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private browserStorageService: BrowserStorageService,
    private logger: NGXLogger) { }


  public init(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {

      const lang = this.route.snapshot.firstChild?.paramMap.get('lang');
      this.logger.debug('language service detected url language : ' + lang)
      if (lang && this.supportedLanguages.includes(lang)) {
        this.translate.use(lang);
      } else {
        this.logger.debug('language service no url language use default : ' + this.defaultLanguage)
        this.translate.use(this.defaultLanguage);
      }
    });
  }

  getiCurrentLanguage(): string {
    let lang = 'fr';
    lang = this.translate.currentLang;
    this.logger.debug('current language in serie ' + lang)
    if (this.browserStorageService.getItem('locale')) {
      this.translate.setDefaultLang(this.browserStorageService.getItem('locale'));
      this.translate.use(this.browserStorageService.getItem('locale'));
      this.logger.debug('getseri locale found ' + this.browserStorageService.getItem('locale'));
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      this.browserStorageService.setItem('locale', 'fr');
      this.logger.debug('getserie component  locale not found ' + this.browserStorageService.getItem('locale'));
    }
    return lang;
  }

  getAvailableLanguages(): string {
    let lang = 'fr';
    lang = this.translate.currentLang;
    this.logger.debug('current language in serie ' + lang)
    if (this.browserStorageService.getItem('locale')) {
      this.translate.setDefaultLang(this.browserStorageService.getItem('locale'));
      this.translate.use(this.browserStorageService.getItem('locale'));
      this.logger.debug('getseri locale found ' + this.browserStorageService.getItem('locale'));
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      this.browserStorageService.setItem('locale', 'fr');
      this.logger.debug('getserie component  locale not found ' + this.browserStorageService.getItem('locale'));
    }
    return lang;
  }

  public setLanguage(): string {
    let lang = 'fr';
    lang = this.translate.currentLang;
    this.logger.debug('current language in serie ' + lang)
    if (this.browserStorageService.getItem('locale')) {
      this.translate.setDefaultLang(this.browserStorageService.getItem('locale'));
      this.translate.use(this.browserStorageService.getItem('locale'));
      this.logger.debug('getseri locale found ' + this.browserStorageService.getItem('locale'));
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      this.browserStorageService.setItem('locale', 'fr');
      this.logger.debug('getserie component  locale not found ' + this.browserStorageService.getItem('locale'));
    }
    return lang;
  }

  public changeLanguage(language: string): string {
    let lang = 'fr';
    lang = this.translate.currentLang;
    this.logger.debug('current language in serie ' + lang)
    if (this.browserStorageService.getItem('locale')) {
      this.translate.setDefaultLang(this.browserStorageService.getItem('locale'));
      this.translate.use(this.browserStorageService.getItem('locale'));
      this.logger.debug('getseri locale found ' + this.browserStorageService.getItem('locale'));
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      this.browserStorageService.setItem('locale', 'fr');
      this.logger.debug('getserie component  locale not found ' + this.browserStorageService.getItem('locale'));
    }
    return lang;
  }
}