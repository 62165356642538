import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, shareReplay, tap } from 'rxjs/operators';
import { Serie, StatusType } from '../models/serie';
import { Comment } from '../models/comment';
import { MessageService } from './message.service';
import { environment } from '../../environments/environment';
import { Page, queryPaginated, queryPaginatedAll, queryPaginatedAllLimit } from '../pagination';
import { AppConfigService } from './app-config.service';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from './url.service';
import { AuthenticationService } from '@/_services';

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json', 'Authorization': 'Basic ' +
//       btoa('serge.frezefond@gmail.com:manager12')
//   })
// };

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({ providedIn: 'root' })
export class SerieService {

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private urlService: UrlService,
    private logger: NGXLogger,
  ) {
    this.logger.debug('SerieService')
  }

  private httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + AuthenticationService.getJWTToken() })
  };

  getAuthorSeries(author_id: number): Observable<Serie[]> {
    return queryPaginatedAllLimit<Serie>(this.http, this.urlService.getAuthorsUrl() + author_id + '/series/', 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched authors series`)),
        catchError(this.handleError<Serie[]>('getAuthorSeries', []))
      );

  }

  /** GET series from the server . public series are return  whic means with at least one public episode*/
  getSeries(): Observable<Serie[]> {
    return queryPaginatedAllLimit<Serie>(this.http, this.urlService.getSeriesUrl() + '?status=' + StatusType.PUBLIC_STATUS, 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        map(series => series.filter(
          serie => serie.id != 1
        )
        ),
        tap(episodes => this.log(`fetched series`)),
        catchError(this.handleError<Serie[]>('getSeries', []))
      );
  }

  /** GET series from the server */
  getMySeries(): Observable<Serie[]> {
    return queryPaginatedAllLimit<Serie>(this.http, this.urlService.getSeriesUrl() + '?owner=' + this.authenticationService.currentUserValue.id, 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        map(series => series.filter(
          serie => serie.id != 1
        )
        ),
        tap(episodes => this.log(`fetched series`)),
        catchError(this.handleError<Serie[]>('getSeries', []))
      );
  }

  getMyPublicSeries(): Observable<Serie[]> {
    return queryPaginatedAllLimit<Serie>(this.http, this.urlService.getSeriesUrl() + '?status=1' + '&owner=' + this.authenticationService.currentUserValue.id, 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched series`)),
        catchError(this.handleError<Serie[]>('getSeries', []))
      );
  }
  getMyDraftSeries(): Observable<Serie[]> {
    return queryPaginatedAllLimit<Serie>(this.http, this.urlService.getSeriesUrl() + '?status=2' + '&owner=' + this.authenticationService.currentUserValue.id, 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched series`)),
        catchError(this.handleError<Serie[]>('getSeries', []))
      );
  }
  getSeriesList(urlOrFilter?: string | object): Observable<Page<Serie>> {
    console.log('serieService getSeriesList ');
    return queryPaginated<Serie>(this.http, this.urlService.getSeriesUrl(), urlOrFilter);
  }

  getSeriesListAll(): Observable<Page<Serie>> {
    return queryPaginatedAllLimit<Serie>(this.http, this.urlService.getSeriesUrl(), 100);
  }

  /** GET serie by id. Return `undefined` when id not found */
  getSerieNo404<Data>(id: number): Observable<Serie> {
    const url = this.urlService.getSeriesUrl() + `?id=${id}/`;
    return this.http.get<Serie[]>(url)
      .pipe(
        map(series => series[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} serie id=${id}`);
        }),
        catchError(this.handleError<Serie>(`getSerie id=${id}`))
      );
  }

  /** GET serie by id. Will 404 if id not found */
  getSerie(id: number): Observable<Serie> {
    // const url = this.urlService.getSeriesUrl() + `?id=${id}/`;
    const url = this.urlService.getSeriesUrl() + `${id}/`;
    return this.http.get<Serie>(url, this.httpOptions).pipe(
      tap(_ => this.log(`fetched serie id=${id}`)),
      shareReplay(1),
      catchError(this.handleError<Serie>(`getSerie id=${id}`))
    );
  }

  /** GET number of episodes for serie id */
  /*
  getSerieMaxEpisode(id: number): Observable<Number> {
    const url = `${this.seriesUrl}${id}/getmax/`;
    console.log('serieService getSerieMaxEpisode ');

    return this.http.get<Number>(url, this.httpOptions).pipe(
      tap(_ => this.log(`fetched serie id=${id}`)),
      catchError(this.handleError<Serie>(`getSerie id=${id}`))
    );
  }
  */

  getMyLikedSeries(): Observable<Serie[]> {
    return queryPaginatedAllLimit<Serie>(this.http, this.urlService.getSeriesUrl() + 'mylikedseries/', 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }

  getMySharedSeries(): Observable<Serie[]> {
    return queryPaginatedAllLimit<Serie>(this.http, this.urlService.getSeriesUrl() + 'mysharedseries/', 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }


  getSerieComments(serie_id: number): Observable<Comment[]> {
    return queryPaginatedAllLimit<Comment>(this.http, this.urlService.getSeriesUrl() + serie_id + '/comments/?ordering=-id', 100)
      .pipe(
        map(events => {
          return events.results;
        }
        ),
        // shareReplay(),
        tap(episodes => this.logger.debug(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }
  /* GET series whose name contains search term */
  searchSeries(term: string): Observable<Serie[]> {
    if (!term.trim()) {
      // if not search term, return empty serie array.
      this.logger.debug('empty serie list')
      return this.getSeries();
      // return of([]);
    }
    return queryPaginatedAllLimit<Serie>(this.http, this.urlService.getSeriesUrl() + `?search=${term}`, 100).pipe(
      map(events => {
        return events.results;
      }
      ),
      tap(_ => this.log(`found sseries matching "${term}"`)),
      catchError(this.handleError<Serie[]>('searchSeries', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new serie to the server */
  addSerie(serie: Serie): Observable<Serie> {

    console.log('addSerie: ', serie);
    serie.initial_language = this.translate.currentLang;
    return this.http.post<Serie>(this.urlService.getSeriesUrl(), serie, this.httpOptions).pipe(
      tap((xserie: Serie) => this.log(`added serie w/ id=${xserie.id}`)),
      catchError(this.handleError<Serie>('addSerie'))
    );
  }

  /** DELETE: delete the serie from the server */
  deleteSerie(serie: Serie | number): Observable<Serie> {
    const id = typeof serie === 'number' ? serie : serie.id;
    const url = this.urlService.getSeriesUrl() + `${id}/`;

    return this.http.delete<Serie>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted serie id=${id}`)),
      catchError(this.handleError<Serie>('deleteSerie'))
    );
  }

  /** PUT: update the serie on the server */
  updateSerie(serie: Serie): Observable<any> {
    return this.http.put(this.urlService.getSeriesUrl() + serie.id + '/', serie, this.httpOptions).pipe(
      tap(_ => this.log(`updated serie id=${serie.id}`)),
      catchError(this.handleError<any>('updateSerie'))
    );
  }

  subscribeSerie(serie: Serie): Observable<any> {
    console.log('serieService subscribe: userid ' + this.authenticationService.currentUserValue.id);

    return this.http.post(this.urlService.getSubscriptionsUrl(), {
      owner: this.authenticationService.currentUserValue.id,
      to_serie: serie.id
    }, this.httpOptions);
  }


  rateSerie(serie: Serie, rating: number): Observable<any> {
    console.log('serieService rateSerie: userid ${this.authenticationService.currentUserValue.id} ' +
      'rating ${rating}');

    return this.http.post(this.urlService.getRatingsUrl(), {
      owner: this.authenticationService.currentUserValue.id,
      serie: serie.id, value: rating
    }, this.httpOptions);
  }


  addLikeSerie(serie: Serie): Observable<any> {
    // this.authenticationService.currentUserValue.id,

    return this.http.post(this.urlService.getLikesUrl(),
      {
        owner: this.authenticationService.currentUserValue.id,
        to_content_object: this.urlService.getApiUrlPath() + `series/${serie.id}/`
        // to_serie: serie.id
      }, httpOptions);
  }

  isratedbymeSerie(serieId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getSeriesUrl() + serieId + '/isratedbyme/', httpOptions);
  }
  nbratings(serieId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getSeriesUrl() + serieId + '/nbratings/',
      httpOptions);
  }
  myrating(serieId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getSeriesUrl() + serieId + '/myrating/',
      httpOptions);
  }
  isSubscribedByMeSerie(serieId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getSeriesUrl() + serieId + '/issubscribedbyme/', httpOptions);
  }

  islikedbymeSerie(serieId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getSeriesUrl() + serieId + '/islikedbyme/',
      httpOptions);
  }
  iscommentedbymeSerie(serieId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getSeriesUrl() + serieId + '/iscommentedbyme/',
      httpOptions);
  }
  nblikes(serieId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getSeriesUrl() + serieId + '/nblikes/',
      httpOptions);
  }
  nbcomments(serieId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getSeriesUrl() + serieId + '/nbcomments/',
      httpOptions);
  }
  nbsubscriptions(serieId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getSeriesUrl() + serieId + '/nbsubscriptions/',
      httpOptions);
  }
  nbshares(serieId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getSeriesUrl() + serieId + '/nbshares/',
      httpOptions);
  }

  setPhoto(serieID: number, fileURL: string) {
    const serie: Serie = new Serie();
    serie.id = serieID;
    serie.photourl = fileURL;
    return this.http.put(this.urlService.getSeriesUrl(), serie, this.httpOptions).pipe(
      tap(_ => this.log(`updated serie id=${serie.id}`)),
      catchError(this.handleError<any>('updateSerie'))
    );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    // console.log('serieService: handleError');
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a SerieService message with the MessageService */
  private log(message: string) {
    this.messageService.add('SerieService: ' + message);
  }
}
