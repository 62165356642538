//  Time-stamp: "2016-07-25 06:13:16 MDT"
module.exports = ['A', 'a', 'A', 'a', 'E', 'e', 'E', 'e', 'I', 'i', 'I', 'i', 'O', 'o', 'O', 'o', 'R', 'r', 'R', 'r', 'U', 'u', 'U', 'u', 'S', 's', 'T', 't', 'Y', 'y', 'H', 'h', 'N', 'd', 'OU', 'ou', 'Z', 'z', 'A', 'a', 'E', 'e', 'O', 'o', 'O', 'o', 'O', 'o', 'O', 'o', 'Y', 'y', 'l',
//  0x34
'n',
//  0x35
't',
//  0x36
'j',
//  0x37
'db',
//  0x38
'qp',
//  0x39
'A',
//  0x3a
'C',
//  0x3b
'c',
//  0x3c
'L',
//  0x3d
'T',
//  0x3e
's',
//  0x3f
'z',
//  0x40

'[?]', '[?]', 'B',
//  0x43
'U',
//  0x44
'^',
//  0x45
'E',
//  0x46
'e',
//  0x47
'J',
//  0x48
'j',
//  0x49
'q',
//  0x4a
'q',
//  0x4b
'R',
//  0x4c
'r',
//  0x4d
'Y',
//  0x4e
'y',
//  0x4f

'a', 'a', 'a', 'b', 'o', 'c', 'd', 'd', 'e', "\\@", "\\@", 'e', 'e', 'e', 'e', 'j', 'g', 'g', 'g', 'g', 'u', 'Y', 'h', 'h', 'i', 'i', 'I', 'l', 'l', 'l', 'lZ', 'W', 'W', 'm', 'n', 'n', 'n', 'o', 'OE', 'O', 'F', 'r', 'r', 'r', 'r', 'r', 'r', 'r', 'R', 'R', 's', 'S', 'j', 'S', 'S', 't', 't', 'u', 'U', 'v', "^", 'w', 'y', 'Y', 'z', 'z', 'Z', 'Z', "?", "?", "?", 'C', "\\@", 'B', 'E', 'G', 'H', 'j', 'k', 'L', 'q', "?", "?", 'dz', 'dZ', 'dz', 'ts', 'tS', 'tC', 'fN', 'ls', 'lz', 'WW', "]]", 'h', 'h', 'h', 'h', 'j', 'r', 'r', 'r', 'r', 'w', 'y', "'", "\"", "`", "'", "`", "`", "'", "?", "?", "<", ">", "^", 'V', "^", 'V', "'", "-", "/", "\\\\", ",", "_", "\\\\", "/", ":", ".", "`", "'", "^", 'V', "+", "-", 'V', ".", "\\@", ",", "~", "\"", 'R', 'X', 'G', 'l', 's', 'x', "?",
//  U+2e5 ...
//  ˥   ˦    ˧    ˨     ˩   ˪    ˫
"5", "4", "3", "2", "1", "/", "\\", 'V', "=", "\"", 'V',
//  ˬ  02EF	MODIFIER LETTER LOW DOWN ARROWHEAD
//  ...and also 16 UPA modifiers:
//  ˰ ˱ ˲ ˳ ˴ ˵ ˶ ˷ ˸ ˹ ˺ ˻ ˼ ˽ ˾ ˿

"^",
//  02F0	MODIFIER LETTER LOW UP ARROWHEAD
"<",
//  02F1	MODIFIER LETTER LOW LEFT ARROWHEAD
">",
//  02F2	MODIFIER LETTER LOW RIGHT ARROWHEAD
"o",
//  02F3	MODIFIER LETTER LOW RING
"`",
//  02F4	MODIFIER LETTER MIDDLE GRAVE ACCENT
"``",
//  02F5	MODIFIER LETTER MIDDLE DOUBLE GRAVE ACCENT
"//",
//  02F6	MODIFIER LETTER MIDDLE DOUBLE ACUTE ACCENT
"~",
//  02F7	MODIFIER LETTER LOW TILDE
":",
//  02F8	MODIFIER LETTER RAISED COLON
"[-",
//  02F9	MODIFIER LETTER BEGIN HIGH TONE
"-]",
//  02FA	MODIFIER LETTER END HIGH TONE
"[_",
//  02FB	MODIFIER LETTER BEGIN LOW TONE
"_]",
//  02FC	MODIFIER LETTER END LOW TONE
"_",
//  02FD	MODIFIER LETTER SHELF
"_",
//  02FE	MODIFIER LETTER OPEN SHELF
"<" //  02FF	MODIFIER LETTER LOW LEFT ARROW
];